import React, { useState } from 'react';
import contact from '../../assets/images/contact.png';

const encode = (data) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');

const ContactForm = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setSubmitting(true);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formData,
      }),
    })
      .then(() => {
        setSubmitted(true);
      })
      .catch((error) => alert(error))
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <section className="contact-area pt-100 pb-70" id="get-started">
      <div className="container">
        <div className="section-title">
          <h2>Interested? Let's Talk!</h2>
          <p>
            A simple 'Hi!' would do. We know what questions to ask to figure out
            how we can collaborate.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-form">
              {isSubmitted ? (
                <p>
                  <em>
                    Thanks for getting in touch with us. Someone from our team
                    will get back to you as soon as possible.
                  </em>
                </p>
              ) : (
                <form
                  id="contactForm"
                  name="bf-web-contact"
                  method="POST"
                  netlify-honeypot="bot-input"
                  data-netlify="true"
                  onSubmit={handleSubmit}
                  disabled={isSubmitted}
                >
                  <div style={{ display: 'none' }}>
                    <label>
                      Don’t fill this out if you’re human:{' '}
                      <input name="bot-input" />
                    </label>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          required
                          placeholder="Your name"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          required
                          placeholder="Your email address"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="phoneNumber"
                          className="form-control"
                          required
                          placeholder="Your phone number"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control"
                          cols="30"
                          rows="6"
                          placeholder="Message (optional)"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <button
                        type="submit"
                        className="default-btn"
                        disabled={isSubmitting || isSubmitted}
                      >
                        <i className="flaticon-tick"></i>
                        Submit <span></span>
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <img src={contact} alt="contact" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
